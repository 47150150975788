// jshint strict:false, undef:false, unused:false

/*/
	I4E Enrollment Loader
	Coded by Stephen Ginn
	Updated 2021-03-22
/*/


/*/
================================================================================
	Small Helper Functions
================================================================================
/*/

function getEnrollmentDefaults() {
	return {
		"portal": "DFEWEB",
		"agent": {
			"visible": false,
			"name_first": "Richard",
			"name_middle": "",
			"name_last": "Alpert",
			"phone": "(555) 555-5555",
			"email": "dfe@morganwhite.com"
		},
		"steps": [
			"Member",
			"Dependents",
			"Billing",
			"Confirmation"
		],
		"pages": [
			"/results",
			"/compare",
			"/cart",
			"/bundling-options",
			"/member",
			"/beneficiaries",
			"/dependents",
			"/billing",
			"/confirmation",
			"/success"
		],
		"member": {},
		"plans": [],
		"compare": {},
		"dependents": [],
		"beneficiaries": {},
		"questions": {},
		"fee": 3500,
		"payment": {},
		"terms": {},
		"confirmation": false
	}
}

function getSettings() {
	var member = enrollment.member;

	return {
		"cdn": "https://cdn.cremadesignstudio.com",
		"plans": "/data/plans.json",
		"carriers": "/data/carriers.json",
		"articles": "/data/articles.json",
		"highlights": "/data/highlights.json",
		"benefits": "/data/benefits.json",
		"benefitRates": "/data/benefit-rates.json",
		"types": "/data/types.json",
		"riders": "/data/riders.json",
		"cards": "/data/cards.json"
	};
}

function saveData(enrollment) {
	return new Promise((resolve, reject) => {
		fetch("/savesession.php", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(enrollment)
		}).then(handleErrors).then(json => {
			resolve(json);
		}).catch(err => {
			reject(err);
		});
	});
}

function getData() {
	return new Promise((resolve, reject) => {
		fetch("/getsession.php").then(handleErrors).then(json => {			
			window.enrollment = json;
			refreshDOM();
			resolve(json);
		}).catch(err => {
			reject(err);
		});
	});
}

function newSession() {
	return new Promise((resolve, reject) => {
		fetch("/newsession.php").then(handleErrors).then(json => {
			window.enrollment = json;
			refreshDOM();
			resolve(json);
		}).catch(err => {
			reject(err);
		});
	});
}


/*/
================================================================================
	Data Loaders
================================================================================
/*/

function handleErrors(res) {
	if (!res.ok) throw new Error(res);
	return res.json();
}

function getCarriers() {
	window.urls = getSettings();

	var portal = JSON.parse(document.getElementById("data-portal").textContent);

	return new Promise((resolve, reject) => {
		fetch(urls.carriers).then(handleErrors).then(json => {
			return json.filter(c => portal.types.includes(c.type));
		}).then(carriers => {
			resolve(carriers);
		}).catch(err => {
			reject(err);
		});
	});
}

function loadPlans() {
	window.urls = getSettings();

	return new Promise((resolve, reject) => {
		var promiseList = [];

		promiseList[0] = new Promise((resolve, reject) => {
			var options = {
				method: 'GET'
			};

			fetch(urls.plans, options).then(handleErrors).then(plans => {
				resolve(plans);
			}).catch(err => {
				reject(err);
			});
		});

		promiseList[1] = new Promise((resolve, reject) => {
			fetch(urls.carriers).then(handleErrors).then(carriers => {
				resolve(carriers);
			}).catch(err => {
				reject(err);
			});
		});

		Promise.all(promiseList).then(json => {
			var plans = json[0], carriers = json[1];

			plans.forEach(plan => {
				// Match Plans With Carriers
				var match = carriers.find(({ name }) => name === plan.carrier) || false;
				plan.logo = match.src || "https://i4e.cremadesignstudio.com/assets/img/logo-coming-soon.svg";

				plan.layout = {
					"benefits": "",
					"expiry": ""
				};

				plan.links = {
					"modal": "#modal-details"
				};
			});

			resolve(plans);
		});
	});
}

function getCityState(zip) {
	return new Promise((resolve, reject) => {
		var url = `https://ziptasticapi.com/${zip}`;

		fetch(url).then(handleErrors).then(json => {
			var city = json.city;
			json.city = city.charAt(0) + city.toLowerCase().slice(1);

			resolve(json);
		}).catch(err => {
			reject(err);
		});
	});
}

function getActionUrls(plan) {
	var member = enrollment.member;
	member.start = encodeURIComponent(member.start);
	member.dob = encodeURIComponent(member.dob);

	return {
		"details": `/Products/DetailsPartial?GroupProductId=${plan.id}&Zip=${member.zip}&Portal=${enrollment.portal}&EffDate=${member.start}&Birthdate=${member.dob}`,
		"share": `/Products/Share?GroupProductId=${plan.id}`,
		"enroll": `/Enroll/Participant?GroupProductId=${plan.id}&Zip=${member.zip}&Portal=${enrollment.portal}&EffDate=${member.start}&DOB=${member.dob}`
	}
}


/*/
================================================================================
	Comparison Popovers
================================================================================
/*/

function loadComparisonPopover(plan) {
	var template = '';
	
	enrollment.compare[plan.type].forEach(plan => {
		template += `<div class="mb-2">
			<div class="btn-group mb-0">
				<div class="btn btn-xs btn-dark btn-remove">
					<span class="fa-2x">&times;</span>
				</div>

				<div class="btn btn-xs btn-light active nohover text-left">${plan}</div>
			</div>
		</div>`;
	});

	return `${template}
	<hr class="dashed my-2" />

	<div class="pt-3 mb-2 popover-actions">
		<a class="btn btn-pill btn-outline-success btn-compare" href="/compare?type=${plan.type}">Compare</a>
		<a class="btn btn-link text-success btn-clear" href="#">Clear All</a>
	</div>`;
}

function removeCompareItem(label, planType) {	
	var title = label.querySelector(".btn-light").textContent,
		i = enrollment.compare[planType].findIndex(plan => plan === title);

	enrollment.compare[planType].remove(i);
	label.remove(i);
	saveData(enrollment);
}


/*/
================================================================================
	Get Product Card HTML
================================================================================
/*/

function getPlanCard(plan) {
	var planData = JSON.stringify({
		id: plan.id,
		type: plan.type,
		title: plan.title,
		price: plan.price,
		coverage: ["Primary", "Spouse"]
	});

	var ribbon = [
		'<div class="badge-attributes badge-success" title="New Plan"><i class="fas fa-tags"></i></div>',
		'<div class="badge-attributes badge-danger" title="Featured Plan"><i class="fas fa-thumbs-up"></i></div>',
		'<div class="badge-attributes badge-primary" title="Popular Plan"><i class="fas fa-shield-check"></i></div>'
	].random();
	
	var checked = '';
	
	if (enrollment.compare[plan.type]) {
		checked = (enrollment.compare[plan.type].includes(plan.title) ? 'checked' : '');
	}	
	
	return `<div class="plan-wrap plan-wrap-${plan.type}">
		<div class="plan card-${plan.type}">
			${ribbon}

			<div class="logo-wrapper">
				<img src="${plan.logo}" class="logo">
			</div>

			<input type="hidden" name="plan" value='${planData}'>

			<div class="details">
				<h3 class="title"><a data-toggle="modal" href="${plan.links.modal}">${plan.title}</a></h3>
				<p class="subtitle">${plan.description}</p>

				${plan.layout.benefits}

				<div class="price">${plan.price.money()}</div>

				<div class="actions">
					<a class="btn-buy" data-toggle="modal" href="#modal-add-cart"><i class="icon"></i> Buy Now</a>

					${plan.layout.expiry}

					<div class="btn-group">
						<a class="btn-details" data-toggle="modal" href="${plan.links.modal}">View Details</a>
						<a class="btn-doctor" href="${plan.provider}" target="_blank">Find Doctor</a>
					</div>
				</div>
			</div>

			<label class="lbl-compare">
				<div class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" data-toggle="popover" title="Selected Items (Max of 5)" ${checked}>
					<div class="custom-control-label">Add to Compare</div>
				</div>
			</label>
		</div>
	</div>`;
}

// This function has been replaced by a Twig Filter...
function getPlanCardSimple(plan) {
	var planData = JSON.stringify({
		id: plan.id,
		type: plan.type,
		title: plan.title,
		price: plan.price,
		coverage: ["Primary", "Spouse"]
	});

	return `<div class="plan mb-4">
		<div class="logo-wrapper">
			<img src="${plan.logo}" class="logo">
		</div>

		<input type="hidden" name="plan" value='${planData}'>

		<div class="details">
			<h3 class="title"><a data-toggle="modal" href="#modal-details">${plan.title}</a></h3>
			<p class="subtitle">${plan.description}</p>

			<a class="btn-shop" data-toggle="modal" href="#modal-add-cart">Shop Now <i class="fa fa-arrow-right"></i> </a>

			<div class="btn-group">
				<a class="btn-details" data-toggle="modal" href="#modal-details">View Details</a>
				<a class="btn-doctor" href="${plan.provider}" target="_blank">Find Doctor</a>
			</div>
		</div>
	</div>`;
}

function createPlanCards(plans) {
	plans.forEach(plan => {
		var target = document.querySelector(`#tab-${plan.type} .results`);
		if (target) target.insertAdjacentHTML('beforeend', getPlanCard(plan));
	});

	// Add Click Events to Plan Cards
	addPlanClickEvents();

	// Initialize Popovers and add Click Events
	// Note: The ONLY reason I'm using any jQuery here, is because Bootstrap 4 requires it...
	var triggers = S('[data-toggle="popover"]');

	triggers.forEach(trigger => trigger.addEventListener("click", e => {		
		var plan = JSON.parse(trigger.closest(".plan").querySelector('[name="plan"]').value);

		// Add or Remove Comparison Items, Based on Checkbox Value
		if (trigger.checked) {
			if (! enrollment.compare.hasOwnProperty(plan.type)) {
				enrollment.compare[plan.type] = [];
			}
			
			if (enrollment.compare[plan.type].includes(plan.title) == false && enrollment.compare[plan.type].length < 5) {
				enrollment.compare[plan.type].push(plan.title);
			}
		} else {
			enrollment.compare[plan.type] = enrollment.compare[plan.type].filter((value, index, arr) => value != plan.title);
		}

		saveData(enrollment);
		
		$(trigger).popover({
			html: true,
			content: loadComparisonPopover(plan)
		});
		
		$(trigger).popover('show').on('shown.bs.popover', function (e) {
			var $popover = $('#' + trigger.getAttribute("aria-describedby")),
				$remove = $popover.find('.btn-remove'),
				$compare = $popover.find('.btn-compare'),
				$clear = $popover.find('.btn-clear');

			refreshDOM();

			$remove.on('click', function (e) {
				e.stopImmediatePropagation();

				removeCompareItem(this.closest('.btn-group'), plan.type);
				saveData(enrollment);
				refreshDOM();
			});

			$clear.on('click', function (e) {
				e.preventDefault();
			    e.stopImmediatePropagation();

				// Uncheck all the comparison checkboxes
				var checkboxes = S('[data-toggle="popover"]');
				checkboxes.forEach(checkbox => checkbox.checked = false);
				enrollment.compare = [];

				saveData(enrollment);

				// Hide Yo Popover
				$popover.popover('hide');
			});
		});
	}));

	// Alternative Pure JS Function
	document.body.addEventListener("click", e => {
		var popover = S("#" + e.target.getAttribute("aria-describedby"));

		if (e.target.getAttribute('data-toggle') !== 'popover' && popover.length === 0) {
			S('.popover.show').forEach(el => el.remove());
		}
	});
}


/*/
================================================================================
	Comparison Page
================================================================================
/*/

function createComparisonCards(plans) {
	var target = document.querySelector("#compare-plans");

	enrollment.compare.forEach(thisTitle => {
		var plan = plans.find(({ title }) => title === thisTitle),
			planData = JSON.stringify({
				id: plan.id,
				type: plan.type,
				title: plan.title,
				price: plan.price,
				coverage: ["Primary", "Spouse"]
			}),
			template = '';

		switch(plan.type) {
			case 'dental':
			case 'vision':
				var rows = '';

				plan.benefits.rows.forEach(benefit => {
					rows += `<tr>
						<th scope="row" class="benefit">${benefit.name}</th>
						<td class="text-left waiting">${benefit.waiting}</td>
						<td class="amount">${benefit.cost}</td>
					</tr>`;
				});

				plan.benefitsTable = `<table class="table-sm table-benefits">${rows}</table>`;
				plan.deductible = plan.deductible.split('*')[0].split('.')[0];
				plan.maximum = plan.maximum.split('/')[0];

				template = `<div class="col">
					<div class="plan">
						<input type="hidden" name="plan" value='${planData}'>

						<div class="card-body">
							<h3 class="mb-0 title"><a data-toggle="modal" href="#modal-details">${plan.title}</a></h3>
							<p class="lead mb-2">${plan.description}</p>
							<div class="h3 mt-0">${plan.price.money()}</div>

							<div class="d-flex justify-content-center">
								<a class="btn btn-sm" data-toggle="modal" href="#modal-details">View Details</a>
								<a class="btn btn-sm" href="${plan.provider}" target="_blank">Find Doctor</a>
							</div>
						</div>

						<div class="details p-0">
							<ul class="list-group list-group-flush">
								<li class="list-group-item">
									<h4 class="mb-0">Waiting / Costs</h4>
									<p class="mb-0">${plan.details.benefitsTable}</p>
								</li>

								<li class="list-group-item">
									<h4 class="mb-0">Deductible</h4>
									<p class="mb-0">${plan.details.deductible}</p>
								</li>

								<li class="list-group-item">
									<h4 class="mb-0">Office Copay</h4>
									<p class="mb-0">${plan.details.copay}</p>
								</li>

								<li class="list-group-item">
									<h4 class="mb-0">Plan Maximum</h4>
									<p class="mb-0">${plan.details.maximum}</p>
								</li>

								<li class="list-group-item pb-0">
									<a class="btn-buy" data-toggle="modal" href="#modal-add-cart"><i class="icon"></i> Buy Now</a>

									<span class="expiry">
										<i class="icon"></i> 2 days left to enroll
									</span>
								</li>
							</ul>
						</div><!--/card-footer-->
					</div><!--/card-->
				</div><!--/col-->`;
			break;
			case 'medical':
				var listitems = ``;

				for (const [benefit, value] of Object.entries(plan.benefits)) {
					var description = ``;

					for (var [subbenefit, subvalue] of Object.entries(value)) {
						if (typeof subvalue == 'number') subvalue = subvalue.money().split('.00')[0];
						description += `<p class="mb-0">${subbenefit}: ${subvalue}</p>`;
					}

					listitems += `<li class="list-group-item">
						<h4 class="mb-0">${benefit}</h4>
						${description}
					</li>`;
				}

				template = `<div class="col">
					<div class="plan">
						<input type="hidden" name="plan" value='${planData}'>

						<div class="card-body">
							<h3 class="mb-0 title"><a data-toggle="modal" href="#modal-details">${plan.title}</a></h3>
							<p class="lead mb-2">${plan.description}</p>
							<div class="h3 mt-0">${plan.price.money()}</div>

							<div class="d-flex justify-content-center">
								<a class="btn btn-sm" data-toggle="modal" href="#modal-details">View Details</a>
							</div>
						</div>

						<div class="details p-0">
							<ul class="list-group list-group-flush">
								${listitems}
							</ul>
						</div><!--/card-footer-->
					</div><!--/card-->
				</div><!--/col-->`;
			break;
			case 'identity':
				template = `<div class="col">
					<div class="plan">
						<input type="hidden" name="plan" value='${planData}'>

						<div class="card-body">
							<h3 class="mb-0 title"><a data-toggle="modal" href="#modal-details">${plan.title}</a></h3>
							<p class="lead mb-2">${plan.description}</p>
							<div class="h3 mt-0">${plan.price.money()}</div>

							<div class="d-flex justify-content-center">
								<a class="btn btn-sm" data-toggle="modal" href="#modal-details">View Details</a>
							</div>
						</div>

						<div class="details p-0">
							<ul class="list-group list-group-flush">
								<li class="list-group-item">
									<h4 class="mb-0">Field</h4>
									<p class="mb-0">Value</p>
								</li>
							</ul>
						</div><!--/card-footer-->
					</div><!--/card-->
				</div><!--/col-->`;
			break;
			case 'telemed':
				template = `<div class="col">
					<div class="plan">
						<input type="hidden" name="plan" value='${planData}'>

						<div class="card-body">
							<h3 class="mb-0 title"><a data-toggle="modal" href="#modal-details">${plan.title}</a></h3>
							<p class="lead mb-2">${plan.description}</p>
							<div class="h3 mt-0">${plan.price.money()}</div>

							<div class="d-flex justify-content-center">
								<a class="btn btn-sm" data-toggle="modal" href="#modal-details">View Details</a>
							</div>
						</div>

						<div class="details p-0">
							<ul class="list-group list-group-flush">
								<li class="list-group-item">
									<h4 class="mb-0">Field</h4>
									<p class="mb-0">Value</p>
								</li>
							</ul>
						</div><!--/card-footer-->
					</div><!--/card-->
				</div><!--/col-->`;
			break;
		}

		// Insert Plan Card
		target.insertAdjacentHTML('beforeend', template);
	});

	// Add Click Events to Plan Cards
	addPlanClickEvents();
}


function loadComparisonCards(selector) {	
	return new Promise((resolve, reject) => {
		fetch("/layouts/comparison-cards" + location.search).then(res => res.text()).then(html => {
			// Insert Plan Cards
			var target = document.querySelector(selector);
			target.insertAdjacentHTML('beforeend', html);

			// Add Click Events to Plan Cards
			addPlanClickEvents();

			var arrows = {
					"left": '<svg height="62" width="32"><line x1="0" y1="31" x2="30" y2="2" style="stroke:currentColor;stroke-width:3" /><line x1="0" y1="29" x2="30" y2="58" style="stroke:currentColor;stroke-width:3" /></svg>',
					"right": '<svg height="62" width="32"><line x1="30" y1="31" x2="0" y2="2" style="stroke:currentColor;stroke-width:3" /><line x1="30" y1="29" x2="0" y2="58" style="stroke:currentColor;stroke-width:3" /></svg>'
				}

			$('.slider-comparison').slick({
				fade: false,
				infinite: false,
				speed: 750,
				autoplay: false,
				prevArrow: `<a class="carousel-control left slick-prev pl-0">${arrows.left}</a>`,
				nextArrow: `<a class="carousel-control right slick-next pr-0">${arrows.right}</a>`,
				slidesToShow: 3,
				slidesToScroll: 3,
				responsive: [
					{
						breakpoint: 320,
						settings: {
							swipeToSlide: true,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 992,
						settings: {
							swipeToSlide: false,
							swipe: false,
							slidesToShow: 2,
							slidesToScroll: 2,
						}
					},
					{
						breakpoint: 1200,
						settings: {
							swipeToSlide: false,
							swipe: false,
							slidesToShow: 3,
							slidesToScroll: 3,
						}
					}
				]
			});

			resolve(html);
		}).catch(err => {
			reject(err);
		});
	});
}


/*/
================================================================================
	Create New Dependent
================================================================================
/*/

function getDependentButtons(enrollment) {
	var html = '';

	if (enrollment.dependents.length) {
		enrollment.dependents.forEach(dependent => {
			html += `<div class="btn-group mb-1">
				<span class="btn btn-sm btn-outline-secondary border-right-0 nohover">${dependent.name_first} ${dependent.name_last}</span>
				<a class="btn btn-sm btn-outline-secondary border-left-0"><i class="fa fa-times"></i></a>
			</div> `;

			html += `<div class="btn-group mr-2 mb-1">
				<span class="btn btn-xs btn-secondary nohover w-auto">Plan Three Title</span>
				<a class="btn btn-xs btn-secondary w-auto"><i class="fa fa-times"></i></a>
			</div>`;
		});

		html = `<label class="w-100">Dependents</label><div class="mb-3">${html}</div>`;
	}

	return html;
}

function getProductButtons(enrollment) {
	var html = '';

	if (enrollment.plans.length) {
		enrollment.plans.forEach(plan => {
			html += `<div class="btn-group mr-2 mb-1">
				<span class="btn btn-xs btn-secondary nohover w-auto">${plan.title}</span>
				<a class="btn btn-xs btn-secondary w-auto"><i class="fa fa-times"></i></a>
			</div>`;
		});
	}

	return html;
}

function getProductCheckboxes(enrollment) {
	var html = '';

	if (enrollment.plans.length) {
		enrollment.plans.forEach(plan => {
			html += `<label class="custom-control custom-checkbox d-flex">
				<input type="checkbox" class="custom-control-input" checked>
				<span class="custom-control-label">${plan.title}</span>
			</label>`;
		});

		html = `<h4>Enrolled Products</h4>${html}`;
	}

	return html;
}

function addDependent(e) {
	e.preventDefault();

	var form = e.target,
		fields = serializeJSON(form),
		template = '',
		target = '',
		dependent = {
			"id": Date.now(),
			"name_first": fields.name_first,
			"name_last": fields.name_last,
			"dob": fields.dob,
			"gender": fields.gender,
			"relationship": fields.relationship
		}

	enrollment.dependents.push(dependent);

	saveData(enrollment);
	refreshDOM();

	if (e.target.querySelector("table")) {
		target = document.querySelector("#tbl-dependents tbody");

		template = `<tr data-id="${dependent.id}">
			<th scope="row" class="pl-0">${dependent.name_first} ${dependent.name_last}</th>
			<td>${dependent.dob}</td>
			<td>${dependent.gender}</td>
			<td>${dependent.relationship}</td>
			<td align="right" class="pr-0"><a data-toggle="modal" href="#modal-dependent-edit"><i class="fa fa-pencil"></i> Edit</a></td>
		</tr>`;
	} else {
		target = document.querySelector("#formDependents .list-group");

		template = `<li class="list-group-item dependent" data-id="${ dependent.id }">
			<div class="row">
				<div class="col-md-4 font-weight-bold name">
					${dependent.name_first} ${dependent.name_last}
				</div>

				<div class="col-9 col-md-4 details">
					${dependent.dob}, ${dependent.gender}, ${dependent.relationship}
				</div>

				<div class="col-3 col-md-4 text-right actions">
					<a data-toggle="modal" href="#modal-dependent-edit"><i class="fa fa-pencil"></i> Edit</a>
				</div>
			</div>

			<label class="small text-muted mb-0">Enrolled Products</label>
			<div class="input-group input-group-sm">
				<div class="form-control d-flex flex-wrap border-right-0 h-auto pb-0">
					${getProductButtons(enrollment)}
				</div>

				<div class="input-group-append">
					<a class="btn btn-primary d-flex align-items-center" data-toggle="modal" href="#modal-dependent-edit"><i class="fa fa-plus"></i></a>
				</div>
			</div>
		</li>`;
	}

	target.insertAdjacentHTML('beforeend', template);

	var submitBtn = document.querySelector('.nav-actions .btn-continue');
	submitBtn.removeAttribute("disabled");
	submitBtn.classList.remove("disabled");

	form.reset();
	form.querySelector('[type=submit]').blur();

	document.querySelector(".page .card-spouse").classList.add("d-none");

	if (document.querySelector(".alert-no-dependents")) {
		document.querySelector(".alert-no-dependents").classList.add("d-none");
	}

	$('.modal').modal('hide');
}


/*/
================================================================================
	Add New Rider
================================================================================
/*/

function addRider(e) {
	e.preventDefault();

	var target = document.querySelector(".enrollment-summary .line-items"),
		card = e.target.closest(".plan"),
		addon = {
			"title": card.querySelector(".title").textContent,
			"subtitle": card.querySelector(".subtitle").textContent,
			"price": card.querySelector(".price").textContent.split('/')[0]
		},
		template = `
		<li class="list-group-item">
			<div class="d-flex justify-content-between">
				<div class="item">${addon.title}</div>
				<div class="item">${addon.price}</div>
			</div>
		</li>`;

	target.insertAdjacentHTML('beforeend', template);
	card.closest(".rider").remove();
}


/*/
================================================================================
	DOM Refreshers
================================================================================
/*/

function updateCart() {
	// Update Cart Quantities
	document.body.setAttribute("data-plans", enrollment.plans.length || 0);
	S(".link-cart .cart-count").forEach(el => el.textContent = enrollment.plans.length);
}

// https://stackoverflow.com/a/40793748
function refreshDOM(element=document, scope=window) {
	element.querySelectorAll('[data-ref]').forEach(el => {
		var ref = el.getAttribute("data-ref");
		el.textContent = ref.split('.').reduce((obj, prop) => obj[prop], scope);
	});

	updateCart();
}

function editStartDate(btn) {
	var listItem = btn.closest(".start-date");
	enrollment.member.start = listItem.querySelector('[name="start"]').value;
	saveData(enrollment);

	getData();
	refreshDOM();
}

function updateSearch(e) {
	e.preventDefault();

	enrollment.member = serializeJSON(e.target);

	getCityState(enrollment.member.zip).then(locale => {
		enrollment.member.city = locale.city;
		enrollment.member.state = locale.state;
		saveData(enrollment);
		refreshDOM();
		e.target.submit();
	});
}

// Save Current Plan to Session Storage when Buttons are Clicked
function addPlanClickEvents() {
	S('[href="#modal-details"], [href="#modal-edit-plan"], [href="#modal-add-cart"]').forEach(btn => {
		btn.addEventListener("click", e => {
			sessionStorage.setItem("plan", btn.closest(".plan, .cart-item").querySelector('[name="plan"]').value);
		});
	});
}

function standardSubmit(e) {
	e.preventDefault();

	saveData(window.enrollment).then(msg => {
		refreshDOM();
		e.target.submit();
	});
}
