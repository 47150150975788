// jshint strict:false, undef:false, unused:false

/*/
	Date Manipulation
	Stephen Ginn at Crema Design Studio
	Last Updated on 2020-03-05
/*/


function getExpiryDate(days) {
	var someDate = new Date();
	someDate.setDate(someDate.getDate() + days);

	var dd = str_pad(someDate.getDate() - 2),
		mm = str_pad(someDate.getMonth() + 1),
		yy = someDate.getFullYear();

	return mm + '/'+ dd + '/'+ yy;
}
