// https://stackoverflow.com/questions/72768/how-do-you-detect-credit-card-type-based-on-number

function detectCardType(number) {
    var re = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/
    }

    for(var key in re) {
        if(re[key].test(number)) return key
    }
}
