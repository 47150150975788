// jshint strict:false, undef:false, unused:false

/*/
	Form Fetcher v1.0.0 (2021-07-01)
	Form Submission with Async/Await
	Stephen Ginn at Crema Design Studio

	// How to Initialize
	contact({
		debug: false,
		selector: '#YourFormSelector',
		recipient: '#YourRecipientSelector',
		alert: '#YourAlertSelector',
		inputs: '#YourInputsSelector',
		btn: '#YourSubmitButtonSelector',
		loaded(settings) {
			console.log("We've initialized a FormCarry form!", this);
			console.log(settings);
		},
		success() {
			this.alert.innerHTML = this.msg;
			this.alert.className = "alert alert-success";
		},
		error() {
			this.alert.innerHTML = this.msg;
			this.alert.className = "alert alert-danger";
		}
	});
/*/

// Multi-Language Success and Error Messages
const translate = (key, lang) => {
	lang = lang || document.documentElement.lang;

	var translations = {
		en: {
			success: "Thank you for contacting us. We will be in touch.",
			error: "We experienced an error processing your form. Please make sure ALL required fields are complete and try again."
		},
		es: {
			success: "Gracias por contactarnos. En breve nos comunicaremos con usted.",
			error: "Estamos presentando dificultades para procesar su mensaje. Por favor complete todas las areas de información requeridas e intentelo nuevamente."
		}
	}

	return translations[lang][key];
}

async function submit(form) {
	var payload = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		},
        body: JSON.stringify(serializeJSON(form))
	}

    if (form.enctype == "multipart/form-data") {
        //payload['headers']['Content-Type'] = "multipart/form-data";
        delete payload.headers['Content-Type'];
        payload["body"] = new FormData(form);
    }

	return (await fetch(form.action, payload)).json();
}

function contact(options) {
	var settings = extend({
		debug: false,
		selector: '[data-fetch]',
		recipient: '[name=recipient]',
		alert: '[data-alert="status"]',
		inputs: '[name]',
		btn: '.btn-submit'
	}, options);

	if (options && (options.loaded || options.success || options.error)) {
		var callback = true;
	}

	var forms = S(settings.selector);

	// Initialize Forms and Event Listener(s)
	forms.forEach(form => {
		form["recipient"] = S(settings.recipient, form)[0];
		form["alert"] = S(settings.alert, form)[0];
		form["inputs"] = S(settings.inputs, form);
		form["btn"] = S(settings.btn, form)[0];

		// Set default values
		form.setAttribute("method", "POST");
		form.setAttribute("accept-charset", "UTF-8");

		// Optional: Switch form action with dropdown
		if (form.recipient) {
			form.recipient.addEventListener("change", el => {
				action = el.target.options[el.target.selectedIndex].getAttribute("data-action");

				if (action) {
					form.btn.removeAttribute("disabled");
					form.setAttribute("data-post", action);
				} else {
					form.btn.setAttribute("disabled", true);
					form.removeAttribute("data-post");
				}
			});
		}

		// Return Callback After Initialization
		if (callback && typeof options.loaded === 'function') {
			options.loaded.call(form, settings);
		}

		form.addEventListener("submit", async (e) => {
			e.preventDefault();

			try {
				let results = await submit(form);

				// Return Callback After Successful Submission
				if (callback && typeof options.success === 'function') {
					form["msg"] = translate("success");
					options.success.call(form);
				}
			} catch (e) {
				if (settings.debug) console.log(e);

				// Return Callback After Failed Submission
				if (callback && typeof options.error === 'function') {
					form["msg"] = translate("error");
					options.error.call(form);
				}
			}
		});
	});
}
