// jshint strict:false, undef:false, unused:false

/*/
	Form Helpers
	Stephen Ginn at Crema Design Studio
	Last Updated on 2021-07-01
/*/

// Serialize Forms as either a URL String or JSON object:
const serialize = form => new URLSearchParams(new FormData(form)).toString();
const serializeJSON = form => Object.fromEntries(new FormData(form));

// Go to Next Field if Maxlength is Hit
function autofocus(selector) {
	var inputs = document.querySelectorAll(selector + " input");

	inputs.forEach((input, i) => {
		input.addEventListener("keyup", e => {
			var maxlength = Number(input.maxlength),
				length = input.value.split('').length;

			if (input.maxlength && length === maxlength) {
				inputs[i + 1].focus();
			}
		});
	});
}

// Quick Toggle Functions for Forms
function toggle(selector) {
	var toggles = document.querySelectorAll(selector);

	toggles.forEach(toggle => {
		toggle.addEventListener("click", e => {
			var targets = document.querySelectorAll(toggle.getAttribute('data-target'));

			targets.forEach(target => {
				var reqFields = target.querySelectorAll('[required]');

				switch(toggle.getAttribute('data-toggle')) {
					case 'show':
						target.classList.remove("d-none");
						reqFields.forEach(el => el.disabled = false);
					break;
					case 'hide':
						target.classList.add("d-none");
						reqFields.forEach(el => el.disabled = true);
					break;
				}
			});
		});
	});
}

// Required Checkbox Groups
function checkgroups(selector = '.checkbox-group.required input[type="checkbox"]') {
	document.querySelectorAll(selector).forEach(checkbox => {
		checkbox.addEventListener("click", e => {
			var chkgroup = checkbox.closest('.checkbox-group'),
				checked = Array.from(chkgroup.querySelectorAll(':checked')).map(e => e.value).length > 0,
				checkboxes = chkgroup.querySelectorAll('[type="checkbox"]');

			if (checked) {
				checkboxes.forEach(chk => chk.removeAttribute('required'));
			} else {
				checkboxes.forEach(chk => chk.setAttribute('required', 'required'));
			}
		});
	});
}
