// jshint strict:false, undef:false, unused:false

/**
	Flexbox Continuous Logo Slider
	CSS-animated continuous flexbox slider with speed (pixels per second), shuffle,
	and autosize by area options. Written in pure javascript; no jQuery required!

	(c) 2019 Stephen Ginn
	Usage: conveyor(target, logos, options)
/*/

function extend(a, b){
    for(var key in b) {
        if(b.hasOwnProperty(key)) {a[key] = b[key];}
	}
    return a;
}

function resizeByArea(width, height, targetArea) {
	var newWidth  = Math.sqrt(targetArea * width / height);
	var newHeight = Math.sqrt(targetArea * height / width);
	newWidth  = newWidth > 0.5 ? Math.round(newWidth) : 1;
	newHeight = newHeight > 0.5 ? Math.round(newHeight) : 1;

	return [newWidth, newHeight];
}

function slider(target, logos, options) {
	// NOTE: targetArea was loosely based on the following formula:
	// (total group width / total logos) * desired height

	var settings = extend({
		speed: 90,
		shuffle: true,
		autosize: true,
		targetArea: 19600
	}, options);

	var el = document.querySelector(target);

	el.insertAdjacentHTML('beforeend', '<div class="row-logos"><div class="group"></div></div>');

	var row = el.querySelector(".row-logos"),
		group = row.querySelector(".group"),
		totalWidth = 0;

	if (settings.shuffle) {
		logos.sort(function () {return Math.random() - 0.5;});
	}

	for (var i = 0; i < logos.length; i++) {
		var logo = logos[i],
			logoWidth = logo.width;

		if (settings.autosize) {
			logoWidth = (resizeByArea(logo.ratio[0], logo.ratio[1], settings.targetArea))[0];
		}

		totalWidth += logoWidth;

		var img = '<img src="' + logo.src + '" alt="' + logo.name + '" width="100%">',
			slide = '<span class="cell" style="width:'+logoWidth+'px;">'+img+'</span>';

		group.insertAdjacentHTML('beforeend', slide);
	}

	row.style.animation = "moveSlideshow " + (totalWidth/settings.speed).toFixed(0) + "s linear infinite";
	group.style.width = totalWidth + "px";

	row.appendChild(group.cloneNode(true));
}
