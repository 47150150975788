/*/
	Console
	Stephen Ginn at Crema Design Studio
	Last Updated on 2019-10-14
/*/

// Console Logging with CSS Support
// print('Lorem Ipsum Dolar', bold);
function print(message, style) {
	var css;

	if (style === "bold") {
		css = "font-weight: bold;";
	}

	console.log("%c%s", css, message);
}

console.print = str => {
	console.log(JSON.stringify(str, null, 4));
};
