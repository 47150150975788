// jshint esversion: 6, strict:false, undef:false, unused:false

/**
	Bootstrap Tabs and Modal Linking v2020
	Stephen Ginn at Crema Design Studio

	Usage: example.com/#modalID OR example.com/#tabID
/*/

function tabModalLinks() {
	var id = location.hash.match(/#[^?&]*/g);

	if (id) {
		id = id[0];

		var el = document.querySelector(id);

		if (el && el.classList.contains("modal")) {
			$(id).modal('show');
		} else if (el && el.classList.contains("tab-pane")) {
			$(id).tab('show');
		}
	}
}

window.addEventListener("DOMContentLoaded", tabModalLinks);
