// jshint strict:false, undef:false, unused:false

/*/
	Arrays
	Stephen Ginn at Crema Design Studio
	Last Updated on 2020-09-17
/*/

// Converts cents to dollars
Number.prototype.money = function() {
	var dollars = (this/100);
    return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
};

// Get Random Integer
function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min;
}

// unique([1,1,1,2,2,4,8,1]);
function unique(arr) {
	return arr.filter((item, i) => arr.indexOf(item) >= i);
}

// [1,1,1,2,2,4,8,1].unique();
Array.prototype.unique = function() {
	return unique(this);
};

// Perform Shallow Extends for Settings
function extend(a, b) {
    for(var key in b) {
        if(b.hasOwnProperty(key)) {a[key] = b[key];}
	}
    return a;
}
