// jshint strict:false, undef:false, unused:false

/*/
	Cremastrap BS4 Plugins
	Stephen Ginn at Crema Design Studio
	Last Updated on 2022-08-11
/*/

// Cremastrap Plugins
// @codekit-append "@cremadesign/cremastrap/js/functions/S.js";
// @codekit-append "@cremadesign/cremastrap/js/functions/arrays.js";
// @codekit-append "@cremadesign/cremastrap/js-dev/tabmodal-links-2020.js";
// @codekit-append "@cremadesign/cremastrap/js/console.js";
// @codekit-append "@cremadesign/cremastrap/js/logo-slider/slider.js";
// @codekit-append "@cremadesign/captcha/dist/captcha.min.js";

// Project Helpers
// @codekit-append "plugins/arrays.js";
// @codekit-append "plugins/dates.js";
// @codekit-append "plugins/forms.js";
// @codekit-append "plugins/form-fetcher.js";
// @codekit-append "plugins/autofill.js";
// @codekit-append "plugins/credit-cards.js";
// @codekit-append "plugins/load-plans.js";

// Third Party Plugins
// @codekit-append "slick-carousel/slick/slick.js";
// @codekit-append "imask/dist/imask.js";
