// jshint strict:false, undef:false, unused:false

/*/
	Form Loading and Autofill Helpers
	Stephen Ginn at Crema Design Studio
	Last Updated on 2021-03-19
/*/


function loadFakeData() {
	return new Promise((resolve, reject) => {
		fetch(`/data/enrollment.json`, {
			method: 'GET'
		}).then(handleErrors).then(json => {
			resolve(json);
		}).catch(err => {
			reject(err);
		});
	});
}

function getFieldNames(form) {
	return [...form.elements].map(el => el.name).filter(el => el).unique();
}

function loadField(form, name, obj) {
	var value = obj[name],
		inputs = form.querySelectorAll(`[name="${name}"]`);

	if (inputs.length > 1) {
		inputs = Array.from(inputs).filter(el => el.value === value);
	}

	if (inputs.length) {
		var input = inputs[0], type = input.type;

		switch(type) {
			case "radio":
				if (input.closest(".btn-group")) {
					input.closest("label").click();
				} else {
					input.checked = true;
				}
			break;
			case "checkbox":
				input.checked = true;
			break;
			default:
				input.value = value || '';
		}
	}
}

function loadForm(form, enrollment) {
	var sections = ['member', 'beneficiaries', 'questions', 'payment', 'terms'];

	sections.forEach(keys => {
		var obj = enrollment[keys];
		Object.keys(obj).forEach(name => loadField(form, name, obj));
	});
}

function loadDependentForm(form, obj) {
	getFieldNames(form).forEach(name => loadField(form, name, obj));
}

function loadForms(el = document) {
	loadFakeData().then(enrollment => {
		el.querySelectorAll("form").forEach(form => loadForm(form, enrollment));
	}).catch(err => {
		console.error(err);
	});
}

function autofill(el = document) {
	loadForms(el);
}
